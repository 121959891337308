/**
 * AJAX Cart management, overriding part of 'woocommerce\assets\js\frontend\add-to-cart.js'
 */

import swal2 from 'sweetalert2';

jQuery(document).ready(function ($) {
    // ADD CHOICES TO CART

    jQuery(document).on('click', '.add_choices_to_cart', function (e) {
        e.preventDefault();

        const buying_options_data = {};
        jQuery('#buying_options_data input').each(function (index, el) {
            buying_options_data[el.name] = el.value;
        });

        const $thisbutton = jQuery(this),
            is_loading = $thisbutton.hasClass('loading');

        if (!is_loading) {

            $thisbutton.removeClass('added');
            $thisbutton.addClass('loading');

            /***** ADD CHOICES TO CART *****/
            const decoded_bundles_content = buying_options_data.selected_bundles ? buying_options_data.selected_bundles.replaceAll('o%m', '"') : '{}';
            const bundles_objects = Object.values(JSON.parse(decoded_bundles_content));
            const fonts_ids = buying_options_data.selected_single_products.split('/').filter(i => i);
            let bundles = [];
            window.current_selected_licence_terms.forEach((term) => {
                const single_bundles = bundles_objects.map(({ bundle_id, bundled_items_ids }) => {

                    const variations = {};

                    bundled_items_ids
                        .split(',')
                        .forEach((bundled_item_id) => {
                            let filtered_licences = [];

                            filtered_licences = window.licence_terms.filter(({ slug }) => slug === term).map(({ name }) => name);
                            const variation_id = jQuery(`.bundles .bundle .product_variations#bundle-${bundle_id}_bundled-item-${bundled_item_id} input[name="${term}"]`).data('variation_id');
                            if (filtered_licences[0]) {
                                variations[`bundle_attribute_licence_${bundled_item_id}`] = filtered_licences[0];
                            }
                            if (variation_id) {
                                variations[`bundle_variation_id_${bundled_item_id}`] = variation_id.toString();
                            }
                            variations[`bundle_quantity_${bundled_item_id}`] = 1;


                        })

                    /* Should return :
                    {
                        product_id: bundle_id,
                        quantity: "1",
                        bundle_attribute_desktop_2: "Up to 3 computers",
                        bundle_attribute_webfont_2: "Up to 10k views/month",
                        bundle_variation_id_2: "126",
                        bundle_quantity_2: "1",
                        bundle_attribute_desktop_5: "Up to 6 computers",
                        bundle_attribute_webfont_5: "Up to 50k views/month",
                        bundle_variation_id_5: "134",
                        bundle_quantity_5: "1",
                    };
                    */

                    return {
                        product_id: bundle_id,
                        quantity: "1",
                        ...variations
                    };
                });
                bundles = [...bundles, ...single_bundles];
            });

            let single_products = [];
            window.current_selected_licence_terms.forEach((term) => {
                const products = fonts_ids
                    .map((product_id) => {
                        const variation_id = jQuery(`.single_products .product#single_product-${product_id} .product_variations input[name="${term}"]`).data('variation_id');
                        const data = {
                            quantity: 1,
                            product_id: variation_id
                        };

                        /* Should return :
                        {
                            attribute_licence: "Up to 3 computers",
                            quantity: "1",
                            'add-to-cart': 134, // variation_id
                            product_id: 134, // variation_id
                        };
                        */

                        return data;
                    });
                single_products = [...single_products, ...products];
            });

            const products_to_add = bundles.concat(single_products);

            execute_ajax_request(products_to_add, 0)

            function execute_ajax_request(all_data, index) {

                const data = all_data[index];

                jQuery.ajax({
                    type: 'POST',
                    url: wc_add_to_cart_params.wc_ajax_url.toString().replace('%%endpoint%%', 'add_to_cart'),
                    data: data,
                    complete: function (response) {
                        if ((index + 1) === all_data.length) {
                            $thisbutton.addClass('added').removeClass('loading');
                        }
                    },
                    success: function (response) {
                        if (!response || (response && response.error)) {
                            console.error(response);
                            swal2.fire({
                                title: "Oops...",
                                text: "An error occured.",
                                showCloseButton: true,
                                buttonsStyling: false,
                                closeButtonHtml: '<i class="fas fa-window-close"></i>',
                                showConfirmButton: false,
                                customClass: {
                                    container: 'error',
                                    closeButton: 'popup-close-button'
                                }
                            });
                        } else {
                            if ((index + 1) < all_data.length) {
                                execute_ajax_request(all_data, index + 1);
                            } else {
                                updateCartQuantity();
                                swal2.fire({
                                    title: 'Articles ajoutés au panier',
                                    text: 'Vous allez automatiquement être redirigé vers votre panier dans quelques secondes...',
                                    showCloseButton: true,
                                    buttonsStyling: false,
                                    closeButtonHtml: '<i class="fas fa-window-close"></i>',
                                    showConfirmButton: false,
                                    customClass: {
                                        closeButton: 'popup-close-button'
                                    }
                                }).then((result) => {
                                    if (result.isDismissed) {
                                        window.location = `${window.url.home}/cart`;
                                    }
                                });

                                setTimeout(function () {
                                    window.location = `${window.url.home}/cart`;
                                }, 3000);
                            }
                        }
                    },
                    error: function (xhr, ajaxOptions, thrownError) {
                        console.error(xhr);
                        console.error(ajaxOptions);
                        console.error(thrownError);
                        swal2.fire({
                            title: "Oops...",
                            text: "An error occured.",
                            showCloseButton: true,
                            buttonsStyling: false,
                            closeButtonHtml: '<i class="fas fa-window-close"></i>',
                            showConfirmButton: false,
                            customClass: {
                                container: 'error',
                                closeButton: 'popup-close-button'
                            }
                        });
                    },
                    dataType: 'json'
                });
            }

        }
    });

    // EMPTY CART / REMOVE ALL

    jQuery(document).on('click', '#cart_remove-all', function (e) {

        e.preventDefault();

        const $thisbutton = jQuery(this),
            is_loading = $thisbutton.hasClass('loading');

        if (!is_loading) {

            $thisbutton.addClass('loading');

            jQuery.ajax({
                type: 'POST',
                url: wc_ajax.ajax_url,
                data: {
                    action: 'empty_cart',
                },
                success: function (response) {
                    if (!response) {
                        swal2.fire({
                            icon: "error",
                            title: "Oops...",
                            text: response,
                            showCloseButton: true,
                        });
                    } else {
                        location.reload();
                    }
                },
                error: function (xhr, ajaxOptions, thrownError) {
                    console.error(xhr);
                    console.error(ajaxOptions);
                    console.error(thrownError);
                    swal2.fire({
                        icon: "error",
                        title: "Oops...",
                        showConfirmButton: true,
                        showCloseButton: true,
                    });
                },
            });

        }
    });

    // UPDATE CART QUANTITY
    function updateCartQuantity() {
        $.ajax({
            type: 'POST',
            url: wc_ajax.ajax_url,
            data: {
                action: 'get_cart_quantity',
            },
            success: function (response) {
                if (response && (response !== '0')) {
                    $('.cart-quantity').html(response);
                } else {
                    $('.cart-quantity').html();
                }
            },
        });
    }

    updateCartQuantity();

});