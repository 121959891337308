
/**
 * Font selection/modification management
 */

jQuery(document).ready(function ($) {

    if ($('body').hasClass('tax-product_cat') && window.licence_terms) {

        let selected_products = [];

        /*******************************
         * Create licence choices HTML
         /********************************/

        const licences_for_display_obj = {};
        window.licence_terms
            .forEach(({ id, slug, name }) => {
                const [licence_type, licence_label] = name.split(window.licence_term_separator);
                const normalized_key = licence_type.replace(/\s/g, '').normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/["']/g, '').toLowerCase();
                if (!licences_for_display_obj[normalized_key]) {
                    licences_for_display_obj[normalized_key] = { licence_type, licences: [] };
                }
                licences_for_display_obj[normalized_key].licences.push({ id, slug, licence_label });
            })

        const licences_for_display_str = Object.keys(licences_for_display_obj).map((normalized_licence_type, index) => {
            let html = '<div class="variation"><label class="switch">';
            html += `<input type="checkbox" id="${normalized_licence_type}-${index}" name="${normalized_licence_type}" value="${normalized_licence_type}" ${index === 0 ? 'checked' : ''} />`;
            html += `<div class="slider"></div><div class="slider-label">${licences_for_display_obj[normalized_licence_type].licence_type}</div>`;
            html += `<select class="select-licences" name="${normalized_licence_type}" id="${normalized_licence_type}">`;
            html += licences_for_display_obj[normalized_licence_type].licences
                .map(({ id, slug, licence_label }) => `<option id="${slug}" value="${id}">${licence_label}</option>`)
                .join('');
            html += '</select>';
            html += '</label></div>';

            if (index === 0) {
                window.current_selected_licence_terms = normalized_licence_type;
            }

            return html;
        }).join('');

        $('#licence_terms').html(licences_for_display_str);

        /*******************************
         * Presave choices
         /********************************/

        // Initialize values
        update_buying_options();
        // On BUNDLE selection change
        $('.bundles input[type="checkbox"]').on('change', function () {
            const name = $(this).attr('name');
            if ($(this).is(':checked')) {
                selected_products.push(name);
            } else {
                selected_products = selected_products.filter(e => e !== name);
            }
            update_buying_options();
        });
        // On FONT selection change
        $('.single_products input[type="checkbox"]').on('change', function () {
            const name = $(this).attr('name');
            if ($(this).is(':checked')) {
                selected_products.push(name);
            } else {
                selected_products = selected_products.filter(e => e !== name);
            }
            update_buying_options();
        });
        // On VARIATION change
        $('.variation input[type="radio"]').on('click', function () {
            update_buying_options();
        });
        $('#licence_terms .variation input[type="checkbox"]').on('change', function () {
            update_buying_options();
        });
        $('#licence_terms .variation select').on('change', function () {
            update_buying_options();
        });

        function update_buying_options() {
            // LICENCES
            window.current_selected_licence_terms = [];
            $('#licence_terms .variation input[type="checkbox"]:checked').each(function () {
                window.current_selected_licence_terms.push($(this).parent().find('select option:checked').attr('id'));
            });
            let valeur = 0;
            $('.product').each(function (index, el) {
                const $this = $(this);
                const price = window.current_selected_licence_terms.reduce(function (acc, term) {
                    valeur = parseFloat($this.find(`.product_variations input[name="${term}"]`).val());
                    return acc + valeur;
                }, 0);
                $(this).find('.product_price').html(`${price.toFixed(2)} ${window.currency_symbol}`);
            });

            $('.bundle').each(function () {

                let price = 0;
                let discounted_price = 0;

                $(this).find('.product_variations').each(function () {
                    const $this = $(this);
                    window.current_selected_licence_terms.forEach(function (term) {
                        const $el = $this.find(`input[name="${term}"]`)
                        price += parseFloat($el.val());
                        discounted_price += parseFloat($el.data('discounted_price'));
                    });

                });
                if (price.toFixed(2) === discounted_price.toFixed(2)) {
                    $(this).find('.product_price').html(`${discounted_price.toFixed(2)} ${window.currency_symbol}`);
                } else {
                    $(this).find('.product_price').html(`<del>${price.toFixed(2)} ${window.currency_symbol}</del> ${discounted_price.toFixed(2)} ${window.currency_symbol}`);
                }
            });

            // BUNDLES
            let selected_bundles_next = '{';
            $('.bundles input[type="checkbox"]').each(function (index, el) {
                if (el.checked) {
                    selected_bundles_next += `${el.value},`;
                }
            });
            selected_bundles_next = (selected_bundles_next.slice(-1) === ',' ? selected_bundles_next.slice(0, -1) : selected_bundles_next) + '}';
            $('#buying_options_data #selected_bundles').val(selected_bundles_next);

            // FONTS
            let selected_fonts_next = '';
            $('.single_products input[type="checkbox"]').each(function (index, el) {
                if (el.checked) {
                    selected_fonts_next += `${el.value}/`;
                }
            });

            $('#buying_options_data #selected_single_products').val(selected_fonts_next);

            // ENABLE/DISABLE add-to-cart button

            if ((selected_products.length > 0) && (window.current_selected_licence_terms.length > 0)) {
                $('.add_choices_to_cart').removeClass('disabled');
                $('.add_choices_to_cart').prop('disabled', false);
            } else {
                $('.add_choices_to_cart').addClass('disabled');
                $('.add_choices_to_cart').prop('disabled', true);
            }

        }

        /**************************************************************
        ****** Hide products which have not the right variations ******
        /**************************************************************/

        $('.single_products .product').each(function (index, el) {
            let $this = $(this);
            const product_id = el.dataset['product_id'];
            const product_variations = [];
            $(this).find('.product_variations input[type=hidden].product_variation').each(function (index, el) {
                product_variations.push(el.name);
            });
            const diff = window.licence_terms.filter(({ slug }) => !product_variations.includes(slug));
            if (diff.length > 0) {
                $(this).css('display', 'none');
                console.log(`%cProduct #${product_id} is missing a licence.`, 'color: red; background-color: white; padding: 5px;');
            }
        });

        $('.bundles .bundle').each(function (index, el) {
            let $this = $(this);
            const bundle_id = el.dataset['bundle_id'];
            const product_variations = [];
            $(this).find('.product_variations input[type=hidden].product_variation').each(function (index, el) {
                product_variations.push(el.name);
            });
            const diff = window.licence_terms.filter(({ slug }) => !product_variations.includes(slug));
            if (diff.length > 0) {
                $(this).css('display', 'none');
                console.log(`%cProduct #${bundle_id} is missing a licence.`, 'color: red; background-color: white; padding: 5px;');
            }
        });

        $('.single_products').removeClass('hide');
        $('.bundles').removeClass('hide');


        /******************************************
        ****** Hide empty typographies types ******
        /******************************************/

        $('.single_products .expandable').each(function (index, el) {
            if ($(this).find('.expandable-content').is(':empty')) {
                $(this).addClass('hide');
            }
        });

    }
});
